import "../Styles/index.scss";
import "@ungap/custom-elements";

import FontFaceObserver from "fontfaceobserver";
import { balanceText } from "balanced-text";

if (window.innerWidth > 768) {
	// wait on font before balancing
	new FontFaceObserver("Harabara Mais").load().then(_ => {
		balanceText({
			watch: true,
			debounce: 200,
			lazyBalance: false,
			disableWait: false
		});
	});
}

import mediumZoom from "@wagich/medium-zoom";
const zoomOptions = {
	background: "rgba(143,144,148,0.8)",
	margin: Math.max(window.innerHeight, window.innerWidth) * 0.025, // 2.5vmax
};
let zoom = mediumZoom(".asset.is-zoomable", zoomOptions);

import component from "svelte-tag";
import ContactForm from "./contact-form.svelte";
new component({ component: ContactForm, tagname: "contact-form", attributes: ["buttontext"] });

import { initializeMaps } from "@wagich/bluc-map";
initializeMaps();

let headerMenu = document.querySelector<HTMLElement>(".header-menu");
if (headerMenu != null) {
	document.body.style.setProperty("--menu-size", `${Math.max(headerMenu.clientWidth, headerMenu.clientHeight) * 1.2}px`);

	let btnMenuOpen = document.getElementById("btnMenuOpen");
	btnMenuOpen?.addEventListener("click", () => {
		document.body.classList.add("is-menu-open");
	});

	let btnMenuClose = document.getElementById("btnMenuClose");
	btnMenuClose?.addEventListener("click", () => {
		document.body.classList.remove("is-menu-open");
	});
}

function setViewportProperty(doc: HTMLElement) {
	let prevClientHeight = 0;

	function handleResize() {
		var clientHeight = doc.clientHeight;
		if (clientHeight === prevClientHeight) return;
		requestAnimationFrame(function updateViewportHeight() {
			doc.style.setProperty("--vh", (clientHeight * 0.01) + 'px');
			prevClientHeight = clientHeight;
		});
	}
	handleResize();

	return handleResize;
}
window.addEventListener("resize", setViewportProperty(document.documentElement));
