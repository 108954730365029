<script lang="ts">
	import { Button, Field, Input, Modal, Notification } from "svelma";

	enum FormState {
		Loading = "loading",
		Ready = "ready",
		Success = "success",
		Error = "error",
	}
	const initialState = {
		formState: FormState.Ready,
		successMessage: "",
		errorMessage: "",
		name: "",
		company: "",
		email: "",
		phone: "",
		message: "",
		timeslot: "",
		location: "",
	};
	const locations = [
		{ id: "loc-office", value: "Bei uns im Büro" },
		{ id: "loc-onsite", value: "Bei Ihnen vor Ort" },
		{ id: "loc-online", value: "Online, bspw. über Zoom oder Teams" },
	];

	export let buttontext = "";
	let isActive = false;
	let state = getInitialData();
	let requestVerificationToken = JSON.parse(document.getElementById("RequestVerificationToken").textContent);

	async function sendAsync(e: Event) {
		let form = e.target as HTMLFormElement;
		if (form?.checkValidity() !== true) {
			return;
		}

		state.formState = FormState.Loading;
		try {
			let response = await fetch("/api/contact", {
				method: "POST",
				headers: {
					"RequestVerificationToken": requestVerificationToken,
					"content-type": "application/json"
				},
				body: JSON.stringify({
					sourceUrl: window.location.href,
					sourceTitle: document.title,
					...state
				})
			});
			if (response.ok) {
				state.formState = FormState.Success;
			} else {
				state.formState = FormState.Error;
			}
		} catch(e) {
			state.formState = FormState.Error;
		}
	}
	
	function open() {
		isActive = true;
		state = getInitialData();
	}

	function close() {
		isActive = false;
	}

	function getInitialData(){
		return JSON.parse(JSON.stringify(initialState));
	}
</script>

<Button type="is-primary" size="is-medium" rounded on:click={open}>{buttontext}</Button>

<div class="modal" class:is-active={isActive}>
	<div class="modal-background" on:click={close}></div>
	<form class="modal-card" on:submit|preventDefault={sendAsync}>
		<header class="modal-card-head">
			<div class="modal-card-title is-family-secondary has-text-primary">Gesprächstermin abmachen</div>
			<button class="delete is-large" aria-label="close" on:click={close}></button>
		</header>
		<section class="modal-card-body">
			<div class="columns">
				<div class="column is-6">
					<Field label="Name">
					  <Input type="text" bind:value={state.name} placeholder="Hans Meier" autocomplete="name" required />
					</Field>
				</div>
				<div class="column is-6">
					<Field label="Firma (optional)">
					  <Input type="text" bind:value={state.company} placeholder="Muster AG" autocomplete="company" />
					</Field>
				</div>
			</div>
			<div class="columns">
				<div class="column is-6">
					<Field label="E-Mail">
					  <Input type="email" bind:value={state.email} placeholder="hans.meier@example.com" autocomplete="email" required />
					</Field>
				</div>
				<div class="column is-6">
					<Field label="Telefon">
					  <Input type="text" bind:value={state.phone} placeholder="033 123 45 67" autocomplete="tel" />
					</Field>
				</div>
			</div>
			
			<Field label="Wo wollen wir uns treffen?">
				<div class="level">
					{#each locations as {id, value}}
						<div class="level-item is-justify-content-flex-start">
							<input class="is-checkradio" id={id} type="radio" name="location" value={value} bind:group={state.location} required>
							<label for={id}>{value}</label>
						</div>
					{/each}
				</div>
			</Field>

			<Field label="Welche Termine wären für Sie passend?">
			  <Input type="text" bind:value={state.timeslot} required />
			</Field>

			<Field label="Welche Themen möchten Sie mit uns besprechen">
			  <Input type="textarea" bind:value={state.message} required />
			</Field>
		</section>
		<footer class="modal-card-foot">
			{#if state.formState === FormState.Ready || state.formState === FormState.Loading}
				<Button nativeType="submit" type="is-primary" loading={state.formState === FormState.Loading}>Abschicken</Button>
				<Button type="is-text" disabled={state.formState === FormState.Loading} on:click={close}>Abbrechen</Button>
			{/if}

			<Notification type="is-success w-100" active={state.formState === FormState.Success}>
				<p class="is-size-4">Wir freuen uns darauf, Sie kennenzulernen.</p>
				<p class="is-size-6">Wir melden uns in Kürze bei Ihnen um den Termin zu bestätigen.</p>
			</Notification>

			<Notification type="is-warning w-100" active={state.formState === FormState.Error}>
				<p class="is-size-4">Hoppla, da lief etwas schief.</p>
				<p class="is-size-6">Es tut uns Leid, aber Ihre Angaben konnten nicht übermittelt werden. Bitte rufen Sie uns an unter <a href="tel:+41334370051">033 437 00 51</a></p>
			</Notification>
		</footer>
	</form>
</div>
